import { useState, useEffect, useRef } from 'react';

const useSave = (value, changeKey) => {
  const [highlighted, setHighlighted] = useState(false);
  const changeRef = useRef(null);
  const valueRef = useRef(null);
  useEffect(() => {
    if (
      valueRef.current
      && value
      && value !== valueRef.current
      && changeKey
      && changeRef.current
      && changeKey === changeRef.current
    ) {
      setHighlighted(true);
      const ti = setTimeout(() => setHighlighted(false), 1500);
      return () => {
        clearTimeout(ti);
        setHighlighted(false);
      };
    }
    changeRef.current = changeKey;
    valueRef.current = value;
    return undefined;
  }, [value, changeKey]);
  return highlighted;
};

export default useSave;
