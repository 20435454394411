import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { sessionActions } from '../session';

const usePageMarker = (pageName) => {
  const nameRef = useRef(pageName);

  useEffect(() => {
    nameRef.current = pageName;
  }, [pageName]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sessionActions.changePage(nameRef.current));
  }, [dispatch]);
};

export default usePageMarker;
