import { useState, useEffect, useCallback, useRef } from 'react';

const useBufferedInput = (referenceField, callback, delay) => {
  const [value, setValue] = useState(referenceField);
  const callBackRef = useRef(null);
  useEffect(() => {
    callBackRef.current = callback;
  }, [callback]);
  useEffect(() => {
    function onTimeOut() {
      callBackRef.current(value);
    }
    if (value !== referenceField) {
      const timerId = setTimeout(onTimeOut, delay);
      return () => clearTimeout(timerId);
    }
    return undefined;
  }, [value, referenceField, delay, callback]);
  const reset = useCallback(() => {
    setValue(referenceField);
  }, [referenceField]);
  return [value, setValue, reset];
};

export default useBufferedInput;
