import { useEffect, useRef } from 'react';

const useOnChange = (callback, field) => {
  const fieldRef = useRef(field);
  useEffect(() => {
    let func = () => {};
    if (field !== fieldRef.current) {
      func = callback();
    }
    fieldRef.current = field;
    return func;
  }, [field]);
};

export default useOnChange;
