import { useEffect, useRef } from 'react';

const useInterval = (callback, delay = 1000) => {
  const callbackRef = useRef();
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  useEffect(() => {
    function tick() {
      callbackRef.current();
    }
    if (delay) {
      const intervalId = setInterval(tick, delay);
      return () => clearInterval(intervalId);
    }
    return undefined;
  }, [delay]);
};

export default useInterval;
